<template>
  <v-sheet
    class="mx-auto my-5"
    max-width="95%"
  >
    <div class="text-center" v-if="loading === true">
      <v-progress-circular :size="70" :width="10" color="blue" indeterminate/>
      <br/>
      お客様の情報を確認中です。<br/>
      処理が完了するまでお待ちください。
    </div>
    <div class="mx-auto" outlined v-if="loading === false">
    <v-card color="pink" dark>
      <v-card-title class="text-center" >GMO決済サービス</v-card-title>
      <p class="ma-5">
        決済がキャンセルされました。<br/>
        ご購入を希望される方は、処方箋表示より再操作をしてください。
      </p>

      <v-btn
        class="ma-4"
      outlined
      large
      color="white"
        v-on:click="go_priscription()" >
        処方箋を再表示する
      </v-btn>
    </v-card>
    </div>

  </v-sheet>
</template>

<script>

export default {
  data () {
      return {
        loading: true,
        order_id: "",
      }
  },
  created: function () {
    this.fetchUsers()
    this.fetchOrder()
  },
  computed: {
    gmo_order_id () {
      return this.$route.params.id
    },
    user(){
      return this.$store.state.userModule.data[this.$store.getters.user.uid]
    },
    order(){
      return this.$store.state.orderModule.data[this.order_id]
    }
  },
  methods: {
    go_priscription: function(){
      console.log("order:", this.order.priscription)
      this.$router.push({ name: "prescription" , params: {id : this.order.priscription.id}, query: { patient_id : this.order.patient_id, patientNumber: this.order.priscription.patientNumber}})
    },

    fetchUsers: async function () {
      await this.$store.dispatch('userModule/fetchById', this.$store.getters.user.uid)
    },
    fetchOrder: async function () {
      const where = [['user_id', '==', this.user.id],['gmoOrderId', '==', this.gmo_order_id]]
      await this.$store.commit('orderModule/RESET_VUEX_EASY_FIRESTORE_STATE')
      await this.$store.dispatch('orderModule/fetchAndAdd',{clauses:{ where, limit: 1 }}).then(querySnapshot => {
        if (querySnapshot.docs.length == 0) {
          console.log("order:", "not found")
        }else{
          querySnapshot.forEach((doc) => {
            this.order_id = doc.id
          })
        }
      })
      this.loading = false
    }
  }
}


</script>
